import IconSpotPizza from '@/fsd/shared/icons/spot-pizza/icon-spot-pizza.vue';
import OrderOrder, { isOrderOrder } from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import IconKitchen from '@/temp/icons/icon-kitchen.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { sleep } from '@/temp/utils';
import { storeToRefs } from 'pinia';
import { computed, markRaw, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const useCheckReadyMeals = () => {
  const ordersStore = useOrders();
  const userStore = useUser();
  const router = useRouter();
  const route = useRoute();

  // нас интересуют только обычные клиентские заказы
  const { orderOrders } = storeToRefs(ordersStore);
  const orders = computed(() => {
    return orderOrders.value.filter<OrderOrder>(isOrderOrder);
  });
  // смотрим, есть ли документ в котором есть разблокированный саджест с едой
  const hasReadyMeals = computed(() => {
    return orders.value.some(o => o.hasKitchenSuggests && !o.hasBlockedSuggests);
  });
  // на страничке ордеров не показываем никаких модалок.
  const isOrderPage = computed(() => {
    return route.name === 'order';
  });
  // на главной показываем без возможности отказаться.
  const isHomePage = computed(() => {
    return route.name === 'home';
  });

  const showReadyMealsConfirm = async (o: OrderOrder) => {
    const suggest = o.suggests.find(s => s.shelf?.type === 'kitchen_on_demand');
    if (!suggest) return;
    // полка точно есть, проверили выше
    const shelf_name = suggest.shelf!.title;
    //если мы на главной, то у пользователя нет выбора )
    if (isHomePage.value) {
      await Notifications.modal({
        title: $gettext('Блюда с кухни заказа %{doc_number} готовы к выдаче', { doc_number: o.orderNumberForView }),
        text: $gettext('Заберите их с полки %{shelf_name} и продолжите сборку заказа.', { shelf_name }),
        buttonText: $gettext('Заберу сейчас'),
        iconTop: {
          icon: markRaw(IconKitchen),
          position: 'center',
        },
      });
      router.push({ name: 'order', params: { order_id: o.order_id } });
      return;
    }
    const confirmed = await Notifications.confirmCenter({
      title: $gettext('Блюда с кухни заказа %{doc_number} готовы к выдаче', { doc_number: o.orderNumberForView }),
      text: $gettext('Заберите их с полки %{shelf_name} и продолжите сборку заказа.', { shelf_name }),
      ok: $gettext('Заберу сейчас'),
      decline: $gettext('Заберу позже'),
      iconTop: {
        icon: markRaw(IconKitchen),
        position: 'center',
      },
    });
    if (confirmed) {
      router.push({ name: 'order', params: { order_id: o.order_id } });
    }
  };

  const checkReadyMeals = async () => {
    // ищем нужный ордер
    const order = orders.value.find(o => o.hasKitchenSuggests && !o.hasBlockedSuggests);
    if (!order) return;
    // Когда мы берем заказ в работу, то сначала он добавляется в стор, а только потом мы переходим на страницу ордера, пока придумал только такую заглушку
    await sleep(100);
    //если мы уже в ордере, то модалка не нужна
    if (isOrderPage.value) return;
    showReadyMealsConfirm(order);
  };

  //в реалтайме мониторим статусы готовой еды.
  watch(hasReadyMeals, value => {
    if (!value || !userStore.experimentByName(experiments.exp_free_cash_register)) return;
    checkReadyMeals();
  });

  //при переходе на главную чекаем, есть ли отложенные документы.
  watch(isHomePage, value => {
    if (!value || !userStore.experimentByName(experiments.exp_free_cash_register)) return;
    checkReadyMeals();
  });
};

export const useCheckBlockedSuggests = (order_id: OrderOrder['order_id']) => {
  const order = computed<OrderOrder | undefined>(() => {
    return useOrders().orderById(order_id) as OrderOrder | undefined;
  });
  const router = useRouter();
  watch(
    () => order.value?.hasOnlyBlockedSuggests,
    async val => {
      if (!val) return;
      const confirmed = await Notifications.confirmCenter({
        title: $gettext('Блюда из заказа %{number} еще готовятся', { number: order.value!.orderNumberForView }),
        text:
          $gettext(
            'Заказ на паузе, он не влияет на вашу скорость сборки. Упакуйте то, что уже собрано и подпишите пакеты.',
          ) +
          '\n' +
          $gettext('Сообщим, когда блюда будут готовы'),
        iconTop: {
          icon: markRaw(IconSpotPizza),
          position: 'left',
        },
        decline: $gettext('Назад к заказу'),
        ok: $gettext('На главную'),
        backdropClick: false,
      });
      if (!confirmed) return;
      await router.push({ name: 'home' });
    },
  );
};
