<template>
  <teleport to="#teleport-confirm">
    <Overlay>
      <div class="sidebar-menu">
        <CommonMenu :menu-config="menuConfig" @click="$emit('close')" @close="$emit('close')" />
      </div>
    </Overlay>
  </teleport>
</template>

<script lang="ts">
import { getOrderTitle } from '@/fsd/entities/order/tools/getOrderTitle';
import CommonMenu from '@/ui/common/menu/common-menu.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    CommonMenu,
  },
  props: {
    order: {
      type: Object,
      default: undefined,
    },
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    additionalTitle(): string {
      return getOrderTitle(this.order?.order_id).value;
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-menu {
  margin-left: auto;
  width: 100vw;
  display: flex;
  background: var(--main-bg);
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-direction: column;

  text-align: left;
  pointer-events: auto;
}
</style>
