import BaseOrder, { OrderTypeEnum } from '@/models/orders/BaseOrder';

interface Conditions {
  need_transfer_act: boolean;
}

export default class AcceptanceMarketOrder extends BaseOrder {
  public type: OrderTypeEnum.acceptance_market = OrderTypeEnum.acceptance_market;
  public conditions: Conditions = { need_transfer_act: false };

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
  }

  get isMarketAcceptance() {
    return Boolean(this.vars.market_orders);
  }
}
