<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="caption"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        :menu-config="menuConfig"
        @close-click="toHomePage"
      />
      <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">{{ hintText }}</Hint>
    </template>

    <template #default>
      <template v-if="lotFilteredSuggests.length > 0">
        <Body1 class="font-bold mx-4 my-2">
          {{ $gettext('Лоты') }}
        </Body1>
        <DynamicScroller :items="lotFilteredSuggests" key-field="suggest_id" :min-item-size="112">
          <template #default="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.status]" :data-index="index">
              <ProductCard
                :key="item.product_id"
                class="mb-2"
                :product-id="item.product_id"
                :need-menu="item.status === 'request' || item.result_count !== 0"
                :badge-data="getBadgeData(item)"
                :menu-config="suggestMenuConfig(item)"
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </template>
      <template v-if="parcelFilteredSuggests.length > 0">
        <Body1 class="font-bold mx-4 my-2">
          {{ $gettext('Посылки') }}
        </Body1>
        <DynamicScroller :items="parcelFilteredSuggests" key-field="suggest_id" :min-item-size="112">
          <template #default="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.status]" :data-index="index">
              <ProductCard
                :key="item.product_id"
                class="mb-2"
                :product-id="item.product_id"
                :need-menu="item.status === 'request' || item.result_count !== 0"
                :badge-data="getBadgeData(item)"
                :menu-config="suggestMenuConfig(item)"
                @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
                @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
              >
                <template #info>
                  <Caption1 color="day-textMinor">
                    <template v-if="isMultiItem(item)">{{ $gettext('Многоместная') }}</template>
                    <template v-else>{{ $gettext('Одноместная') }}</template>
                  </Caption1>
                </template>
              </ProductCard>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </template>

      <EmptyList v-if="uiStateFilter !== 'all' && filteredSuggests.length === 0" />
    </template>

    <template #footer>
      <LayoutFooter>
        <template v-if="uiStateFilter === 'all'">
          <FilterMenu :menu-config="filterMenuConfig" />
          <template v-if="order.conditions.need_transfer_act">
            <UiButton
              v-if="!hasOpenTransferAct"
              data-test="create-transfer btn"
              :is-disabled="!allSuggestDone"
              @click="createTransferAct"
            >
              {{ $gettext('Создать акт') }}
            </UiButton>
            <UiButton
              v-else
              data-test="check-transfer btn"
              background-color="secondary"
              :is-disabled="hasActiveCheckSignal"
              @click="checkTransferAct"
            >
              {{ hasActiveCheckSignal ? $gettext('Запрос обрабатывается') : $gettext('Проверить статус акта') }}
            </UiButton>
          </template>
          <SliderButton
            v-else
            data-test="acceptance_market finish btn"
            :disabled="!allSuggestDone"
            @slide-complete="finishOrder"
          >
            {{ $gettext('Завершить') }}
          </SliderButton>
        </template>
        <UiButton v-else @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import withFilter from '@/mixins/withFilter';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import AcceptanceMarketOrder from '@/models/orders/AcceptanceMarketOrder';
import { AudioService } from '@/services/audio.service';
import { LotReasonsEnum } from '@/services/requests';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { logger } from '@/temp/plugins/logs';
import { BaseError } from '@/types/baseErrorResponse';
import Bar from '@/ui/common/bar/bar.vue';
import EmptyList from '@/ui/common/empty-list.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ProductCard, { BadgeData } from '@/ui/common/product-card/product-card.vue';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import commonMixin from '@/views/common/commonMixin';
import { AxiosError } from 'axios';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: 'all' | 'done' | 'request' | 'part' | 'noProduct' | 'toReturn';
}

export default defineComponent({
  name: 'AcceptanceMarket',
  components: {
    LayoutFooter,
    PageLayout,
    Body1,
    Caption1,
    ProductCard,
    Bar,
    Hint,
    UiButton,
    FilterMenu,
    EmptyList,
    SliderButton,
  },
  mixins: [requestProductCode, requestBarcode, withFilter, commonMixin],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const ordersStore = useOrders();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return { showLoader, itemsStore, ordersStore, router };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): AcceptanceMarketOrder {
      return this.ordersStore.orderById(this.order_id) as any as AcceptanceMarketOrder;
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      const cancelActBtn: MenuItemConfig = {
        buttonText: this.$gettext('Отменить акт приёмки'),
        buttonSecondText: this.$gettext('Есть неотсканированные посылки, другое'),
        onClick: () => this.cancelTransferAct(),
        dataTest: 'cancel transfer-act',
        condition: () => Boolean(this.hasOpenTransferAct),
      };
      menuConfig.push(cancelActBtn);

      return menuConfig;
    },
    contractorName(): string {
      if (!this.order || !this.order.attr.contractor) {
        return '';
      }
      return this.order.attr.contractor;
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return this.suggests;
        case 'done':
          return this.fullCompletedSuggests;
        case 'request':
          return this.requestSuggests;
        case 'noProduct':
          return this.noProductSuggests;
        case 'toReturn':
          return this.suggestToReturn;
      }
      return this.suggests;
    },
    parcelFilteredSuggests(): Suggest[] {
      return this.filteredSuggests.filter(s => s.item?.type === 'parcel');
    },
    lotFilteredSuggests(): Suggest[] {
      return this.filteredSuggests.filter(s => s.item?.type === 'lot');
    },
    controlBarText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} посылок', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          });
        case 'done':
        case 'request':
        case 'noProduct':
        case 'toReturn':
          return this.$ngettext(
            '%{filtered} посылка с таким статусом',
            '%{filtered} посылок с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return this.contractorName;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.finishActiveSuggest({ count: 0 }, suggest);
          },
          dataTest: 'no-product btn',
          condition: () => {
            return (
              checkConditions(suggest, 'all', true) && (suggest.status === 'request' || suggest.result_count !== 0)
            );
          },
        };
        menuConfig.push(noProductBtn);

        const brokenBtn: MenuItemConfig = {
          buttonText: this.$gettext('Повреждён'),
          buttonSecondText: this.$gettext('Есть видимые повреждения лота'),
          onClick: () => {
            this.brokenLot(suggest);
          },
          dataTest: 'broken-lot btn',
          condition: () => {
            const s = suggest;
            if (!s) return false;
            return s.item.type === 'lot' && s.result_count === 1 && !s.reason?.code;
          },
        };
        menuConfig.push(brokenBtn);

        const markAsNonDamagedLotBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как неповрежденный'),
          onClick: () => {
            this.markAsNonDamagedLot(suggest);
          },
          dataTest: 'mark-as-non-damaged-lot btn',
          condition: () => {
            const s = suggest;
            if (!s) return false;
            return Boolean(s.item.type === 'lot' && s.result_count === 1 && s.reason?.code);
          },
        };
        menuConfig.push(markAsNonDamagedLotBtn);

        return menuConfig;
      };
    },
    caption(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Приёмка Маркета');
        case 'done':
          return this.$gettext('Отксанирована');
        case 'request':
          return this.$gettext('Не отсканирована');
        case 'noProduct':
          return this.$gettext('Нельзя принять');
        case 'toReturn':
          return this.$gettext('Возврат приёмки Маркета');
      }
      return this.$gettext('Приёмка Маркета');
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      const menuConfig = [
        {
          buttonText: this.$gettext('Не отсканирована'),
          color: 'secondary',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Нельзя принять'),
          color: 'red',
          count: this.noProductSuggests.length,
          onClick: () => this.setFilter('noProduct'),
        },
        {
          buttonText: this.$gettext('Отксанирована'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
      ];
      return menuConfig;
    },
    hasOpenTransferAct(): boolean {
      return Boolean(this.order?.vars.transfer_act?.status === 'CREATED');
    },
    hasActiveCheckSignal(): boolean {
      return Boolean(this.order.estatus === 'check_transfer_act');
    },
    hintText(): string {
      if (this.hasOpenTransferAct) {
        return this.$gettext('Попросите курьера подписать акт — после этого приёмка будет завершена');
      }
      if (this.uiStateFilter === 'toReturn') {
        return this.$gettext('Отканируйте штрих-код каждой посылки, которую вы возвращаете курьеру');
      }
      return this.$gettext('Отсканируйте каждый лот и каждую посылку, которые вы принимаете');
    },
    suggestToReturn(): Suggest[] {
      return Object.keys(this.groups).reduce((acc, key) => {
        const curGroup = this.groups[key];
        //  в группе больше 1 посылки и среди них есть НЕпринятые
        if (curGroup.length > 1 && curGroup.some(s => s.status === 'done' && s.result_count === 0)) {
          acc.push(...curGroup.filter(s => s.result_count === 1));
        }
        return acc;
      }, [] as Suggest[]);
    },
    groups(): {
      [key: string]: Suggest[];
    } {
      return this.suggests.reduce((acc, val) => {
        const group = val.vars.group;
        if (!group) {
          return acc;
        }
        if (acc[group]) {
          acc[group].push(val);
        } else {
          acc[group] = [val];
        }
        return acc;
      }, {});
    },
    isMultiItem(): (s) => boolean {
      return (s: Suggest) => {
        if (!s.vars.group) return false;
        return this.groups[s.vars.group].length > 1;
      };
    },
    hasMultiItems(): boolean {
      return new Set(this.suggests.map(s => s.vars.group)).size !== this.suggests.length;
    },
  },
  watch: {
    suggestToReturn(val) {
      if (val.length === 0) {
        this.setFilter('all');
      }
    },
    hasMultiItems: {
      //  если в приемке есть многоместные посылки, то говорим об этом, как только узнаем об этом.
      handler(val) {
        if (val && this.requestSuggests.length === this.suggests.length) {
          this.$notification.universalModal({
            title: this.$gettext('В приёмке есть многоместные посылки'),
            text: this.$gettext(
              'Их удобнее отложить в сторону — если в приёмке будет не хватать посылок, курьеру надо будет вернуть часть из них. Когда вы сканируете многоместную посылку будет другой звук.',
            ),
            buttons: [
              {
                color: 'secondary',
                title: this.$gettext('Воспроизвести звук'),
                dontCloseOnClick: true,
                onClick: () => {
                  AudioService.playMultiItem();
                },
              },
              {
                color: 'primary',
                title: this.$gettext('Понятно'),
                dataTest: 'confirm ok-btn',
                onClick: () => {},
              },
            ],
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      const { closeLoader } = this.showLoader();
      try {
        const item = await this.itemsStore.getItemByBarcode(barcode);
        closeLoader();
        if (!item) {
          await this.$notification.modal({
            title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
          });
          AudioService.playError();
          return true;
        }
        const suggest = this.order?.getSuggestsByProductId(item.item_id)[0];
        if (!suggest) {
          await this.$notification.modal({
            title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
          });
          AudioService.playError();
          return true;
        }
        if (this.uiStateFilter === 'toReturn') {
          const hasInToReturnList = this.suggestToReturn.find(s => s === suggest);
          if (hasInToReturnList) {
            await this.finishActiveSuggest({ count: 0 }, suggest);
          }
        } else {
          if (this.isMultiItem(suggest)) {
            AudioService.playMultiItem();
          }
          await this.finishActiveSuggest({ count: 1 }, suggest);
        }
        return true;
      } catch (e) {
        AudioService.playError();
        console.error(e);
        closeLoader();
        await this.$notification.modal({
          title: this.$gettext('Пожалуйста, убедитесь, что вы правильно отсканировали штрих-код посылки.'),
        });
        return true;
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async brokenLot(suggest: Suggest): Promise<void> {
      const hasAccessToAttachment = await this.$notification.confirmCenter({
        title: this.$gettext('Есть доступ к содержимому лота?'),
      });
      const reason = {
        code: hasAccessToAttachment
          ? LotReasonsEnum.DAMAGED_WITH_ACCESS_TO_ATTACHMENT
          : LotReasonsEnum.DAMAGED_WITHOUT_ACCESS_TO_ATTACHMENT,
        count: 1,
      };
      this.finishActiveSuggest(
        {
          count: 1,
          reason,
        },
        suggest,
      );
    },
    markAsNonDamagedLot(suggest: Suggest): void {
      this.finishActiveSuggest(
        {
          count: 1,
          reason: { code: null, count: 1 },
        },
        suggest,
      );
    },
    async finishActiveSuggest(
      {
        count,
        reason,
      }: {
        count: number;
        reason?: { code: LotReasonsEnum | null; count: number };
      },
      suggest: Suggest,
    ): Promise<void> {
      if (!suggest) {
        this.$notification.error.micro(this.$gettext('Произошла ошибка при выполнении задания'));
        logger.error('empty active suggest', {
          type: 'code',
          page: 'accceptance',
          method: 'finishActiveSuggest',
          message: 'empty active suggest',
        });
        return;
      }
      let title: string = '';
      switch (true) {
        case this.uiStateFilter === 'toReturn':
          break;
        case suggest.item.type === 'lot' && count === 0:
          title = this.$gettext('Вы уверены, что лот %{item} отсутствует?', {
            item: suggest.item.title,
          });
          break;
        case count === 0:
          title = this.$gettext('Вы уверены, что посылка %{item} отсутствует?', {
            item: suggest.item.title,
          });
          break;
      }
      if (title) {
        const confirmed = await this.$notification.confirmBottom({
          title,
        });
        if (!confirmed) return;
      }

      const result = await useCheck(this.order_id, {
        suggest_id: suggest.suggest_id,
        count,
        reason,
      });
      if (!result) return;

      this.uiStateNeedBarcodeRequest = true;
    },
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
    async createTransferAct(): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что посылок больше не осталось?'),
        text: this.$gettext('Убедитесь, что посылок, которые можно принять, больше не осталось.'),
      });
      if (!confirmed) {
        return;
      }

      if (this.suggestToReturn.length) {
        await this.$notification.modal({
          title: this.$gettext('Часть многоместных посылок придется вернуть курьеру'),
          text: this.$gettext('Отсканируйте и верните их,а затем отправьте акт на подписание.'),
        });
        this.setFilter('toReturn');
        return;
      }

      const { closeLoader } = this.showLoader();
      try {
        await this.ordersStore.signal({ order_id: this.order.order_id, signal: 'edoc' });
        await useSubscribeOnOrder(this.order_id)(o => {
          if (!o) return true;
          if (['CREATED', 'CLOSED'].includes(o.vars.transfer_act?.status)) {
            return true;
          }
          return false;
        });
        this.$notification.modal({
          title: this.$gettext('Попросите курьера подписать акт'),
          text: this.$gettext(
            'После того, как курьер подпишет акт, приёмка будет завершена, и будет создано задание на размещение.',
          ),
        });
      } catch (e: any) {
        console.error(e);
        if (!e.isAxiosError) {
          return;
        }
        const response = (e as AxiosError<BaseError>)['response'];
        if (response?.data.code === 'ER_CONFLICT' && response?.data.message === 'No Market orders to accept') {
          this.$notification.modal({
            title: this.$gettext('Чтобы закрыть документ, обратитесь в поддержку'),
            text: 'ER_CONFLICT',
          });
        } else if (
          response?.data.code === 'ER_CONFLICT' &&
          response?.data.message === 'Some edoc* signals not processed'
        ) {
          this.$notification.modal({
            title: this.$gettext('Подписание акта в процессе, пожалуйста, подождите'),
            text: 'ER_CONFLICT',
          });
        } else {
          this.$notification.modal({
            title: response!.data.code,
            text: response?.data.message,
          });
        }
      } finally {
        closeLoader();
      }
    },
    async cancelTransferAct(): Promise<void> {
      if (!this.hasOpenTransferAct) {
        return;
      }
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что хотите отменить  акт?'),
        text: this.$gettext('Если отмените, нужно будет создать акт заново и отправить его курьеру на подписание.'),
      });

      if (!confirmed) {
        return;
      }

      const { closeLoader } = this.showLoader();
      try {
        await this.ordersStore.signal({ order_id: this.order.order_id, signal: 'edoc_cancel' });
        await useSubscribeOnOrder(this.order_id)(o => {
          if (!o) return true;
          if (!o.vars.trasfer_act) {
            return true;
          }
          return false;
        });
      } catch (e) {
        console.error(e);
      } finally {
        closeLoader();
      }
    },
    async checkTransferAct(): Promise<void> {
      if (!this.hasOpenTransferAct) {
        return;
      }
      // если естатус равен check_transfer_act - значит сигнал уже отправляли
      if (this.hasActiveCheckSignal) {
        return;
      }
      const { closeLoader } = this.showLoader();
      try {
        await this.ordersStore.signal({ order_id: this.order.order_id, signal: 'edoc_check' });
        await this.$notification.success.micro(this.$gettext('Запрос отправлен'));
      } catch (e: any) {
        console.error(e);
        if (!e.isAxiosError) {
          return;
        }
        const response = (e as AxiosError<BaseError>)['response'];
        if (response?.data.code === 'ER_CONFLICT' && response?.data.message === 'Some edoc* signals not processed') {
          this.$notification.modal({
            title: this.$gettext('Подписание акта в процессе, пожалуйста, подождите'),
            text: 'ER_CONFLICT',
          });
        } else {
          this.$notification.modal({
            title: response!.data.code,
            text: response?.data.message,
          });
        }
      } finally {
        closeLoader();
      }
    },
    getBadgeData(s: Suggest): BadgeData | undefined {
      if (s.status === SuggestStatusEnum.request) {
        return undefined;
      }
      if (s.reason?.code === LotReasonsEnum.DAMAGED_WITH_ACCESS_TO_ATTACHMENT) {
        return {
          color: 'orange-normal',
          text: this.$gettext('Повреждён'),
        };
      }
      if (s.reason?.code === LotReasonsEnum.DAMAGED_WITHOUT_ACCESS_TO_ATTACHMENT) {
        return {
          color: 'orange-normal',
          text: this.$gettext('Повреждён'),
        };
      }
      if (s.result_count === 1) {
        return {
          color: 'lime-toxic',
          text: this.$gettext('Готово'),
        };
      }
      if (s.result_count === 0) {
        return {
          color: 'red-normal',
          text: this.$gettext('Нет'),
        };
      }
    },
    async finishOrder(): Promise<void> {
      const confirmed = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что приняли все посылки?'),
        isHold: true,
      });
      if (!confirmed) return;

      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
  },
});
</script>
