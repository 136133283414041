<template>
  <div
    class="h-5 flex py-0.5 px-1 rounded-md items-center cursor-pointer w-max gap-0.5"
    :class="`bg-${bgColor}`"
    :data-test="props.dataTest || 'order badge'"
  >
    <slot name="icon" />
    <Caption1 class="font-medium" :color="props.textColor">
      <slot name="title" />
    </Caption1>
    <div class="flex-1" />
  </div>
</template>

<script setup lang="ts">
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineProps, defineSlots } from 'vue';

defineSlots<{
  /** Слот для иконки */
  icon?: (props: { class?: string }) => any;
  /** Слот для текста */
  title: () => any;
}>();

const props = defineProps<{
  textColor: string;
  bgColor: string;
  dataTest?: string;
}>();
</script>
