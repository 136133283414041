import BaseOrder from '@/models/orders/BaseOrder';

export default class VisualControlOrder extends BaseOrder {
  public vars: {
    //признак того, что саджесты на списание сгенерированы
    suggests_write_off: boolean;
    stage: string;
  } = { suggests_write_off: false, stage: '' };

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }
}
