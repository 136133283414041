import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';
import { getKeyKey } from '@/temp/utils';

export const getOrderTypesInMenu = gettextWrapper({
  order: $gettext('Сборка заказа'),
  check: $gettext('Локальная инвентаризация'),
  check_more: $gettext('Слепая инвентаризация'),
  inventory_check_more: $gettext('Слепая инвентаризация'),
  writeoff: $gettext('Списание'),
  stowage: $gettext('Размещение'),
  sale_stowage: $gettext('Размещение'),
  weight_stowage: $gettext('Размещение'),
  acceptance: $gettext('Приемка'),
  check_product_on_shelf: $gettext('Пересчеты'),
  inventory_check_product_on_shelf: $gettext('Пересчеты'),
  refund: $gettext('Возвраты заказов'),
  part_refund: $gettext('Возвраты заказов'),
  control: $gettext('Контроль товаров'),
  check_valid_regular: $gettext('Контроль товаров'),
  check_valid_short: $gettext('Контроль товаров'),
  writeoff_prepare_day: $gettext('Контроль товаров'),
  moving: $gettext('Перемещение'),
  shipment: $gettext('Отгрузка'),
  shipment_rollback: $gettext('Возврат отгрузки'),
  hand_move: $gettext('Ручное перемещение'),
  visual_control: $gettext('Контроль внешнего вида'),
  check_final: $gettext('Локальная инвентаризация_КП'),
});

export const orderTypesInMenuList = getKeyKey(getOrderTypesInMenu);

export const getOrderStatuses = gettextWrapper({
  reserving: $gettext('Резервирование'),
  approving: $gettext('Подтверждение'),
  request: $gettext('Запрос'),
  processing: $gettext('Обработка'),
  complete: $gettext('Готов'),
  done: $gettext('Готов'),
  canceled: $gettext('Отменен'),
  pause: $gettext('Приостановлен'),
  failed: $gettext('Невозможно выполнить'),
});

export const orderStatusesList = getKeyKey(getOrderStatuses);
