<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        need-progress
        :progress-config="progressConfig"
        :total="filteredSuggests.length"
        :icons="{ left: uiStateFilter === 'all' ? 'close' : 'back', right: 'menu' }"
        :menu-config="menuConfig"
        @close-click="onBarLeftBtnClick"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint class="my-2 mx-4">{{ hintText }}</Hint>
        <SuggestCardContainer
          :doc-type="order.type"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            filteredSuggests.map(s => {
              s.need_menu = true;
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel="suggestDetails.hide"
      />
      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="uiStateFilter === 'all' && stage === 'control'" :menu-config="filterMenuConfig" />
        <template v-if="uiStateFilter === 'all'">
          <UiButton
            v-if="stage === 'control' && needTrashStage"
            data-test="control footer next-stage-btn"
            @click="nextStage"
          >
            {{ $gettext('Перейти к списанию') }}
          </UiButton>
          <UiButton
            v-if="stage === 'trash' || !needTrashStage"
            data-test="control footer finish-btn"
            :disabled="required.length > 0 && stage === 'trash'"
            @click="onFinish"
          >
            {{ $gettext('Завершить') }}
          </UiButton>
        </template>
        <UiButton
          v-if="uiStateFilter !== 'all'"
          data-test="control back-btn"
          background-color="secondary"
          @click="setFilter('all')"
        >
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrder, useSubscribeOnOrderSignal } from '@/fsd/data/utils/subscribeOnOrder';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getBox2ShelfSuggests, getSuggestsByStatusAndType } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import withFilter from '@/mixins/withFilter';
import { TypeAccountingEnum } from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import VisualControlOrder from '@/models/orders/VisualControlOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { sleep } from '@/temp/utils';
import Bar from '@/ui/common/bar/bar.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import commonMixin from '@/views/common/commonMixin';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiState: {
    isShelfBarcodeSuccess: boolean;
  };
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: string;
}

export default defineComponent({
  name: 'VisualControl',
  components: {
    PageLayout,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    Hint,
    SuggestDetails,
    UiButton,
    FilterMenu,
    ShareOrder,
    ScanShelf,
  },
  mixins: [requestProductCode, requestBarcode, withFilter, commonMixin],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const ordersStore = useOrders();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      shelvesStore,
      productsStore,
      userStore,
      shareOrder,
      suggestDetails,
      scanShelf,
      router,
      detachBtn,
    };
  },
  data(): Data {
    return {
      uiState: {
        isShelfBarcodeSuccess: false,
      },
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): VisualControlOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as any as VisualControlOrder;
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return [...this.box, ...this.required];
        case 'done':
          return this.box;
        case 'request':
          return this.required;
        case 'return':
          return this.returnSuggests;
      }
      return this.suggests;
    },
    required(): Suggest[] {
      if (this.stage === 'control') {
        return this.requestSuggests.filter(
          suggest =>
            suggest.type === 'shelf2box' || (suggest.type === 'box2shelf' && suggest.vars?.stage === 'visual_control'),
        );
      }
      return getBox2ShelfSuggests(this.requestSuggests);
    },
    box(): Suggest[] {
      if (this.stage === 'control') {
        return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.done, SuggestTypeEnum.shelf2box);
      }
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.done, SuggestTypeEnum.box2shelf).filter(
        s => s.vars?.stage !== 'visual_control',
      );
    },
    trashShelfId(): string {
      const suggest = this.suggests.find(
        suggest => suggest.type === 'box2shelf' && suggest.vars?.stage !== 'visual_control',
      );
      if (!suggest) {
        return '';
      }
      return suggest.shelf_id;
    },
    hintText(): string {
      if (!this.order || !this.order.suggests) return '';

      if (this.stage === 'trash') {
        return this.$gettext('Сканируйте полку списания');
      }
      return this.$gettext('Отсканируйте товар');
    },
    box2ShelfSuggests(): Suggest[] {
      return getSuggestsByStatusAndType(this.suggests, SuggestStatusEnum.request, SuggestTypeEnum.box2shelf);
    },
    returnSuggests(): Suggest[] {
      if (this.stage === 'trash') return [];
      return this.box2ShelfSuggests;
    },
    controlBarText(): string {
      return this.$gettext('Контроль внешнего вида');
    },
    controlBarCaption(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Отбор');
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
      }
      return this.$gettext('Отбор');
    },

    needTrashStage(): boolean {
      return this.suggests.some(s => s.status === 'done' && s.result_count && s.result_count > 0);
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      return [
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.box.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'secondary',
          count: this.required.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Вернуть'),
          color: 'orange',
          count: this.returnSuggests.length,
          onClick: () => this.setFilter('return'),
        },
      ];
    },
    progressConfig(): any {
      if (!this.order) return;
      return [
        {
          count: this.box.length,
          color: 'green',
        },
      ];
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        condition: () => this.userStore.experimentByName(experiments.exp_tsd_companion),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        if (!suggest) return menuConfig;
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => {
            this.finishActiveSuggest({ count: 0 }, suggest);
          },
          condition: () => checkConditions(suggest, 'all', true) && suggest.status === 'request',
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    stage(): 'control' | 'trash' {
      //- visual_control
      // - trash
      if (this.order?.vars.stage === 'trash') {
        return 'trash';
      }
      return 'control';
    },
  },
  watch: {
    box2ShelfSuggests: {
      handler() {
        this.uiState.isShelfBarcodeSuccess = this.box2ShelfSuggests.length === 0;
      },
      deep: true,
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const onScanBarcode = async barcode => {
        if (!this.order || !this.order.suggests) return false;
        // Обработка этапа списания
        if (this.stage === 'trash') {
          if (!this.trashShelfId) {
            return false;
          }
          const shelf = await this.shelvesStore.getShelfById(this.trashShelfId);
          if (shelf?.barcode === barcode) {
            await this.completeFinalStage();
            return false;
          } else {
            this.$notification.error.micro(this.$gettext('Отсканирована неверная полка'));
            return false;
          }
        }

        return true;
      };
      const { product } = await this.requestProductCode({ checkSuggests: true, onScanBarcode });
      if (!product || (this.required.length === 0 && this.box.length > 0)) {
        return true;
      }
      return await this.selectProduct(product);
    },
    getControlStageSuggests(product_id: string): Suggest[] {
      return this.suggests.filter(
        suggest =>
          suggest.product_id === product_id &&
          suggest.status === 'request' &&
          (suggest.type === 'shelf2box' || (suggest.type === 'box2shelf' && suggest.vars?.stage === 'visual_control')),
      );
    },
    onBarLeftBtnClick(): void {
      if (this.uiStateFilter === 'all') {
        this.router.push({ name: 'home' });
      } else {
        this.uiStateFilter = 'all';
      }
    },
    async finishActiveSuggest({ count = 0 }: Pick<Model, 'count'>, suggest: Suggest): Promise<void> {
      if (!suggest) {
        return;
      }

      const canWriteoff = await this.checkCanWriteoff(count, suggest);
      if (!canWriteoff) return;

      if (suggest.type === 'shelf2box') {
        const result = await useShelf2Box(this.order_id, { suggest_id: suggest.suggest_id, count });
        if (!result) return;
      } else {
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },

    async checkCanWriteoff(writeoff: number, suggest: Suggest): Promise<boolean> {
      // списать ноль всегда можно.
      if (!writeoff) return true;
      //  если есть, что списать, то нужно проверить резерв. вдруг кто то уже купил списанку(
      const { closeLoader } = this.showLoader('Загружаем продукт');
      const availables = await this.productsStore.fetchAvailable(suggest.product!.product_id);
      closeLoader();
      //  если не получили остатки, то передаем валидацию на бек.
      if (!availables) return true;
      const available = availables.find(a => a.shelf_id === suggest.shelf_id);
      // на полке достаточно и для заказа и для списания
      if (available && available.count >= available.reserved + writeoff) {
        return true;
      }

      const reserved = available?.reserved ?? writeoff;
      const canWriteoff = available ? available.count - reserved : 0;
      const text = this.$gettext(
        'Для клиентского заказа необходимо: %{reserved}\n Вы можете списать не более %{count}',
        {
          reserved: getCountToView({
            count: reserved,
            type_accounting: suggest.product?.type_accounting || TypeAccountingEnum.unit,
          }),
          count: getCountToView({
            count: canWriteoff,
            type_accounting: suggest.product?.type_accounting || TypeAccountingEnum.unit,
          }),
        },
      );
      await this.$notification.modal({
        title: this.$gettext('Упс, товар нужен для заказа!'),
        text,
      });
      return false;
    },
    async selectProduct(product): Promise<boolean> {
      const controlStageSuggests = this.getControlStageSuggests(product.product_id);
      if (controlStageSuggests.length === 1) {
        await this.suggestDetails.asyncShow(controlStageSuggests[0]);
        return true;
      }
      if (controlStageSuggests.length === 0) {
        this.$notification.error.micro(this.$gettext('Этот товар уже проверен!'));
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const targetSuggest = controlStageSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (targetSuggest) {
        await this.suggestDetails.asyncShow(targetSuggest);
        return true;
      } else {
        this.$notification.error.micro(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
    },
    async onFinish(): Promise<void> {
      try {
        let confirmed = false;
        if (this.stage === 'control') {
          await this.nextStage();
          confirmed = true;
        }
        const confirm =
          confirmed ||
          (await this.$notification.confirmBottom({
            title: this.$gettext('Вы уверены, что разместили все товары?'),
            ok: this.$gettext('Да'),
            decline: this.$gettext('Нет'),
          }));
        if (!confirm) return;
        this.uiStateNeedBarcodeRequest = false;
        const result = await useEndOrder(this.order_id);
        if (result) {
          this.router.push({ name: 'home' });
        } else {
          this.uiStateNeedBarcodeRequest = true;
        }
      } catch (error) {
        logger.error(error, { method: 'onFinish', type: 'visual control' });
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
    async nextStage(): Promise<void> {
      if (this.returnSuggests.length > 0) {
        await this.$notification.modal({
          title: this.$gettext('Некоторые товары входят в другое задание — их нужно вернуть на полки'),
        });
        this.setFilter('return');
        return;
      }
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что проверили все товары?'),
        ok: this.$gettext('Да'),
        decline: this.$gettext('Нет'),
      });
      if (!confirm) return;
      this.suggestDetails.hide();
      const { closeLoader, updateLoader } = this.showLoader(undefined, this.order_id);
      try {
        await this.ordersStore.signal({
          order_id: this.order!.order_id,
          signal: 'next_stage',
        });
        updateLoader(this.$gettext('Ожидаем перехода задания на этап списания'), this.order_id);
        await this.waitNextStage();
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        closeLoader();
      }
    },
    async waitNextStage(): Promise<void> {
      if (this.order?.vars.suggests_write_off) {
        return;
      }
      await useSubscribeOnOrder(this.order_id)(o => {
        if (!o) return true;
        if (o.vars.suggests_write_off) {
          return true;
        }
        return false;
      });
      // Думаю, что бек не готов принимать endOrder сразу же после смены стадии,
      // попробую такую заглушку.
      await sleep(500);
    },
    async completeFinalStage(): Promise<void> {
      const { closeLoader } = this.showLoader(this.$gettext('Списываем товары'), this.order_id);
      try {
        const permit = this.userStore.permitByName('order_signals');
        // наличие пермита на отправку сигнала, означает, что он поддерживается
        if (Array.isArray(permit) && permit.includes('complete_final_stage')) {
          // отправка сигнала на закрытие саджестов на полку списания
          await this.ordersStore.signal({
            order_id: this.order!.order_id,
            signal: 'complete_final_stage',
          });
          // ждем когда сигнал перейдет в done
          await useSubscribeOnOrderSignal(this.order_id)(s => {
            return Boolean(s?.done);
          }, 'complete_final_stage');
          await sleep(500);
          //снимаем лоадер
        }
      } catch (error) {
        console.error(error);
      } finally {
        closeLoader();
      }
    },
  },
});
</script>
