<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6635_9909)">
      <rect x="8" y="5" width="40" height="40" rx="20" fill="#575858" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 31.875C31.797 31.875 34.875 28.797 34.875 25C34.875 21.203 31.797 18.125 28 18.125C24.203 18.125 21.125 21.203 21.125 25C21.125 28.797 24.203 31.875 28 31.875ZM28 33.75C32.8325 33.75 36.75 29.8325 36.75 25C36.75 20.1675 32.8325 16.25 28 16.25C23.1675 16.25 19.25 20.1675 19.25 25C19.25 29.8325 23.1675 33.75 28 33.75ZM29.25 21.875C29.25 22.5654 28.6904 23.125 28 23.125C27.3096 23.125 26.75 22.5654 26.75 21.875C26.75 21.1846 27.3096 20.625 28 20.625C28.6904 20.625 29.25 21.1846 29.25 21.875ZM28.9375 25.625C28.9375 25.1072 28.5178 24.6875 28 24.6875C27.4822 24.6875 27.0625 25.1072 27.0625 25.625V28.75C27.0625 29.2678 27.4822 29.6875 28 29.6875C28.5178 29.6875 28.9375 29.2678 28.9375 28.75V25.625Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_6635_9909"
        x="0"
        y="0"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.341176 0 0 0 0 0.345098 0 0 0 0 0.345098 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6635_9909" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6635_9909" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
