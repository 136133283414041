import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import Event from '@/models/events/Event';
import { EventTakeRequest } from '@/services/requests';
import { EventTakeResponse } from '@/services/response';
import { useIndicators } from '@/store/indicators';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import axios, { Cancel, CancelTokenSource } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

interface Subscription {
  key: Event['key'];
  cb: CB[];
}

export type CB = (code: EventTakeResponse['code'], events?: Event['data'][]) => any;

const axiosForEvent = axios.create();
axiosBetterStacktrace(axiosForEvent);

axiosForEvent.interceptors.response.use(
  response => {
    useIndicators().clearEventErrors();
    return response;
  },
  error => {
    // логируем только сетевые ошибки. игнорируем отмены поллинга, замену ключей, перезапрос по таймауту.
    if (error.isAxiosError) {
      useIndicators().addEventError();
      const data = error.response?.data;

      // Удаляем курсор, чтобы одинаковые запросы склеивались
      // В противном случае, мы бы получили миллион уникальных ошибок в error-booster из-за уникального курсора
      if (data) delete data.state;

      logger.error(error, {
        source: 'EVENT_ERROR',
        additional: {
          requestUrl: error.config?.url,
          requestData: error.config?.data,
          responseStatus: error.response?.status,
          responseData: data,
        },
      });
    }

    return Promise.reject(error);
  },
);
axiosForEvent.interceptors.request.use(request => {
  useIndicators().addEventPoll();
  const headers: {
    [header: string]: string;
  } = { 'Content-Type': 'application/json' };
  if (useUser().isAuthenticated) {
    headers.Authorization = useUser().token;
  }
  request.headers = headers;
  return request;
});

class EventService {
  private delay: number = 1;
  private state: string | undefined = undefined;
  private readonly timeout: number = 15;
  private readonly timeoutForAxios: number = 20;
  private source: CancelTokenSource | undefined = undefined;
  private subscriptions: Subscription[] = [];
  private started: boolean = false;
  public url: string = localStorage.getItem('event') || '/api/ev/take';

  public subscribe = (key: Event['key'], cb: CB) => {
    let subscription = this.findSubscription(key);
    if (subscription) {
      subscription.cb.push(cb);
    } else {
      subscription = { key, cb: [cb] };
      this.subscriptions.push(subscription);
      this.state = undefined;
      if (this.source) {
        this.source.cancel('update keys');
      }
    }
    return () => {
      if (subscription) {
        const cbIdx = subscription.cb.findIndex(item => item === cb);
        if (cbIdx !== -1) subscription.cb.splice(cbIdx, 1);
        if (subscription.cb.length === 0) {
          const subIdx = this.subscriptions.findIndex(s => s === subscription);
          if (subIdx !== -1) this.subscriptions.splice(subIdx!, 1);
        }
      }
    };
  };

  public stopEvents = () => {
    this.state = undefined;
    this.started = false;
    useIndicators().setHasEventsPoll(false);
    if (this.source) {
      this.source.cancel('stop');
      this.source = undefined;
    }
  };

  private take = async () => {
    if (!this.started) return;
    this.source = axios.CancelToken.source();
    try {
      const keys = this.subscriptions.map(item => item.key);
      const user_id = useUser().userId;
      const url = window.location.pathname;
      const parts = url.split('/');
      let order_id: string | undefined = parts[parts.length - 1];
      if (order_id.length !== 44) order_id = undefined;
      const payload: EventTakeRequest = { keys, state: this.state, timeout: this.timeout, user_id, order_id };
      const response = await axiosForEvent.post<EventTakeResponse>(this.url, payload, {
        cancelToken: this.source.token,
        timeout: this.timeoutForAxios * 1000,
      });
      this.delay = 1;
      this.state = response.data.state;
      const code = response.data.code;
      useDevtoolsStore().pushEvent(response);
      switch (code) {
        case 'OK':
          this.emit(response.data.events, code);
          break;
        case 'INIT':
          this.emitAll(code);
          break;
        case 'MAYBE_DATA_LOST':
          logger.error('MAYBE_DATA_LOST', {
            additional: {
              events: response.data,
              request: payload,
            },
          });
          this.emitAll(code);
          break;
        default:
          break;
      }
      setTimeout(this.take, 0);
    } catch (e) {
      logger.error(e);
      if (axios.isCancel(e)) {
        // если причина отмены стоп, то прекращаем отправку запросов
        if ((e as Cancel).message === 'stop') return;
        //иначе шлем еще один
        setTimeout(this.take, 0);
        return;
      }
      setTimeout(this.take, this.delay * 1000);
      if (this.delay < 16) this.delay *= 2;
    }
  };

  public init = () => {
    if (!this.started) {
      this.started = true;
      useIndicators().setHasEventsPoll(true);
      this.take();
    }
  };

  private emitAll = (code: EventTakeResponse['code']) => {
    this.subscriptions.forEach(s => {
      s.cb.forEach(cb => {
        cb(code);
      });
    });
  };

  private emit = (events: Event[], code: EventTakeResponse['code']) => {
    // прилетели какие-то ивенты, теперь нужно разделить их на группы по ключу и отправить массив с ивентами слушателям

    // собираем ивенты в кучу по ключам
    const mappedEvents = events
      .map(e => ({
        key: e.key,
        event: e.data,
      }))
      .reduce((acc, cur) => {
        if (acc[cur.key.toString()]) {
          acc[cur.key.toString()].events.push(cur.event);
        } else {
          acc[cur.key.toString()] = {
            key: cur.key,
            events: [cur.event],
          };
        }
        return acc;
      }, {});

    Object.keys(mappedEvents).forEach(prop => {
      const { key, events } = mappedEvents[prop];
      const subscription = this.findSubscription(key);
      if (!subscription) return;
      subscription.cb.forEach(cb => {
        cb(code, events);
      });
    });
  };

  private findSubscription = (key: Event['key']): Subscription | undefined => {
    const stringKey = JSON.stringify(key);
    const idx = this.subscriptions.findIndex(s => JSON.stringify(s.key) === stringKey);
    if (idx === -1) return undefined;
    return this.subscriptions[idx];
  };
}

export default new EventService();
