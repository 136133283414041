<template>
  <div
    id="root"
    class="root"
    :class="{
      'is-rtl-main': isRTL,
      'deli-theme': theme === 'deli',
      'dark-theme': theme === 'dark',
    }"
  >
    <RouterView v-show="!isActiveCamera" />
    <Updater />
    <NotificationsGroup />
    <ActionGroup />
    <Devtools />
    <Indicators />
    <Loader />
    <QrAim v-if="isActiveCamera" />
  </div>
</template>
<script lang="ts">
import Updater from '@/components/updater/updater.vue';
import Devtools from '@/devtools/devtools.vue';
import { checkClientTime } from '@/fsd/data/utils/checkClientTime';
import Indicators from '@/fsd/features/indicators/index';
import { useCheckNetwork } from '@/fsd/features/tools/useCheckNetwork';
import QrAim from '@/fsd/shared/ui/QrAim/QrAim.vue';
import { useCheckAssignCourier } from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/MaybeRover/useCheckAssignCourier';
import ActionGroup from '@/fsd/shared/ui/action/ActionGroup.vue';
import { useCheckReadyMeals } from '@/hooks/useCheckReadyMeals';
import { useUpdates } from '@/hooks/useUpdates';
import { useOrders } from '@/store/modules/orders';
import { useScanner } from '@/store/modules/scanner';
import { useUser } from '@/store/modules/user';
import NotificationsGroup from '@/temp/components/notifications-group.vue';
import Loader from '@/ui/common/loader/loader.vue';
import { initParams } from '@/utils/query-configurator';
import { mapState, storeToRefs } from 'pinia';
import { defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ActionGroup,
    QrAim,
    Devtools,
    Loader,
    NotificationsGroup,
    Updater,
    Indicators,
  },
  setup() {
    const router = useRouter();
    // запускаем отслеживание готовой еды. оповещаем сборщика о возможности проолжить сборку
    useCheckReadyMeals();
    // Запускаем отслеживание назначений курьера для документов с maybe_rover
    useCheckAssignCourier();
    // Проверяем время на клиенте, как только оно будет получено
    checkClientTime();

    const ordersStore = useOrders();
    const userStore = useUser();

    // если пользователь разлогинился, то пушим на страницу логина
    const { isAuthenticated } = storeToRefs(userStore);
    useCheckNetwork();
    watch(isAuthenticated, val => {
      if (!val) router.push('/login');
    });

    return { ordersStore, userStore };
  },
  computed: {
    ...mapState(useUser, ['storeId']),
    language() {
      return this.userStore.language;
    },
    theme() {
      return this.userStore.theme;
    },
    isRTL() {
      return this.userStore.isRTL;
    },
    isActiveCamera() {
      return useScanner().isActiveCamera;
    },
  },
  watch: {
    storeId: {
      handler(value, oldValue) {
        if (value && !oldValue) {
          this.ordersStore.startPolling();
        }
        if (!value && oldValue) {
          this.ordersStore.stopPolling();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    initParams();
    useUpdates();
  },
});
</script>

<style lang="scss">
.root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
