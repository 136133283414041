<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <OrderHeader
        ref="header"
        :order_id="order_id"
        :suggest_id="activeSuggest?.suggest_id"
        @start-barcode-request="startBarcodeRequest"
        @stop-barcode-request="stopBarcodeRequest"
      />
    </template>
    <template #default>
      <!-- Сборка позиций  -->
      <template v-if="!isOrderDone">
        <RequiredCardWrapper
          :suggests="suggestsForCollect"
          :active-suggest-index="activeSuggestIndex"
          :order_id="order_id"
          :collected="collected"
          @finish="completeActiveSuggest"
          @input-sherlock-count="checkNeedReportAfterSherlock"
          @change-active-suggest="index => (activeSuggestIndex = index)"
        />
      </template>
      <FinishOrderCard
        v-if="isOrderDone"
        :order_id="order_id"
        :suggests="suggestsForCollect"
        :packing="order.packingSuggests"
        @done="finishOrder"
      />
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import RequiredCardWrapper from '@/fsd/widgets/order/RequiredCard/RequiredCardWrapper.vue';
import FinishOrderCard from '@/fsd/widgets/order/finish-order/finish-order-card.vue';
import { useCheckBlockedSuggests } from '@/hooks/useCheckReadyMeals';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import IconCoffee from '@/temp/icons/icon-coffee.vue';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';
import { deleteItem } from '@/utils/localStorageHelper';
import OrderHeader from '@/views/Order/OrderHeader.vue';
import { useCollectOrder } from '@/views/Order/useCollectOrder';
import { defineComponent, markRaw, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Order',
  components: {
    RequiredCardWrapper,
    OrderHeader,
    PageLayout,
    FinishOrderCard,
  },
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader, closeComponentLoaders } = useLoader();
    const router = useRouter();

    const header = ref<InstanceType<typeof OrderHeader>>();

    const {
      suggestsForCollect,
      activeSuggestIndex,
      activeSuggest,
      selectNext,
      collected,
      completeActiveSuggest,
      startBarcodeRequest,
      stopBarcodeRequest,
    } = useCollectOrder(props.order_id);

    useCheckBlockedSuggests(props.order_id);

    useHandleOrderStatus(props.order_id, {
      cancel: () => {
        activeSuggestIndex.value = 0;
      },
    });
    return {
      showLoader,
      closeComponentLoaders,
      router,
      header,
      suggestsForCollect,
      activeSuggestIndex,
      activeSuggest,
      selectNext,
      collected,
      completeActiveSuggest,
      startBarcodeRequest,
      stopBarcodeRequest,
    };
  },
  computed: {
    order(): OrderOrder | undefined {
      return useOrders().orderById(this.order_id) as OrderOrder;
    },
    isOrderDone(): boolean {
      if (this.order?.target !== OrderTargetEnum.complete) return false;
      return this.suggestsForCollect.every(s => s.isCompleted);
    },
  },
  methods: {
    toHomePage(): Promise<any> {
      return this.router.push({ name: 'home' });
    },
    async finishOrder(): Promise<void> {
      if (!this.order) {
        return this.toHomePage();
      }
      if (this.order.isOrderPaused) {
        this.$notification.error.micro(this.$gettext('Данный заказ обрабатывается оператором, пожалуйста подождите'));
        return;
      }
      try {
        const orderId = this.order_id;
        const order = this.order;
        const result = await useEndOrder(this.order_id);
        if (!result) {
          return;
        }
        deleteItem('roverScan', orderId);
        await this.toHomePage();
        if (useUser().experimentByName(experiments.exp_sten_gustaf_thulin)) return;
        if (order.hasCoffee) {
          await this.$notification.modal({
            title: this.$gettext('Не забудьте про готовые напитки заказа %{number}', {
              number: order.orderNumberForView,
            }),
            titleAlign: 'text-left',
            text: this.$gettext('Заберите их у кофемашины и соберите в заказ.'),
            textAlign: 'text-left',
            iconTop: {
              icon: markRaw(IconCoffee),
              position: 'center',
            },
            buttonText: this.$gettext('Понятно'),
            // класс, для отображения модалки поверх других модальных окон
            class: '!z-[1100]',
            dataTest: 'order-number-reminder',
          });
        } else {
          await this.$notification.modal({
            title: this.$gettext('Заказ %{number}', {
              number: order.orderNumberForView,
            }),
            titleAlign: 'text-center',
            class: '!z-[1100]',
            dataTest: 'order-number-reminder',
          });
        }
      } catch (error) {
        logger.error(error, { method: 'finishOrder', type: 'order' });
      }
    },
    checkNeedReportAfterSherlock(count): void {
      // Если на полке НЕ учетное кол-во и товара может не хватать для сборки
      if (count < this.activeSuggest.count!) {
        this.header.showReportMenu();
      }
      this.startBarcodeRequest();
    },
  },
});
</script>
