export enum BrandingEnum {
  yandex = 'yandex',
  b2b = 'b2b',
}

interface BrandingService {
  branding?: BrandingEnum;

  getBranding(): BrandingEnum;

  setBranding(branding: BrandingEnum): void;

  isYandex(): boolean;

  isB2B(): boolean;
}

export const BrandingService: BrandingService = {
  branding: undefined,
  getBranding() {
    if (!this.branding) {
      const local = localStorage.getItem('branding');
      if (local) {
        this.branding = local as BrandingEnum;
      }
    }
    return this.branding || BrandingEnum.yandex;
  },
  setBranding(branding: BrandingEnum) {
    this.branding = branding;
    localStorage.setItem('branding', branding);
  },
  isYandex() {
    return this.getBranding() === BrandingEnum.yandex;
  },
  isB2B() {
    return this.getBranding() !== BrandingEnum.yandex;
  },
};
