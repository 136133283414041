import { getDoneSuggests, getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { defineComponent } from 'vue';

interface Data {}

// Миксин для общих механик, что относятся ко всем типам документов,
// потенциальное место для саджестов, отфильтрованных по стадиям и статусам и тд
export default defineComponent({
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  data(): Data {
    return {};
  },
  computed: {
    order(): BaseOrder | undefined {
      return useOrders().orderById(this.order_id);
    },
    suggests(): Suggest[] {
      if (!this.order?.suggests) return [];
      return this.order.suggests;
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    doneSuggests(): Suggest[] {
      return getDoneSuggests(this.suggests);
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
  },
});
