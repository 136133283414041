<template>
  <div class="bg-white px-1.5 flex gap-1 min-h-[24px]">
    <!--  Дата  -->
    <Caption1 v-if="date" class="text-secondary">
      {{ date }}
    </Caption1>

    <!--  Время  -->
    <Caption1 v-if="duration" class="flex-1"> {{ duration }}ms</Caption1>

    <!--  Статус  -->
    <Caption1
      v-if="status"
      :class="{
        success: status >= 200 && status < 300,
        error: status >= 400,
      }"
      class="flex-1"
    >
      {{ status }}
    </Caption1>

    <!--  Контент  -->
    <Caption1 v-if="content" class="text-primary break-words flex-[3]">
      {{ content }}
    </Caption1>

    <!--  Раскрыть лог  -->
    <button v-if="moreInfo" class="h-6 w-6" @click="emit('more-info')">i</button>
  </div>
</template>

<script setup lang="ts">
import { BarcodeLog } from '@/devtools/sections/barcodes/barcodeLog';
import { EventLog } from '@/devtools/sections/events/eventLog';
import { NetworkLog } from '@/devtools/sections/networkLog';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineEmits, defineProps } from 'vue';
import { RequestLog } from './requests/requestLog';

defineProps<{
  date: NetworkLog['date'];
  status?: NetworkLog['statusCode'];
  duration?: RequestLog['duration'];
  // TODO подправить тип ивента
  content: BarcodeLog['barcode'] | RequestLog['path'] | EventLog['requestBody'];
  moreInfo?: boolean;
}>();

const emit = defineEmits(['more-info']);
</script>

<style scoped>
.error {
  color: #e54d42;
}
.success {
  color: #239808;
}
</style>
