// 1) Отправить запрос
// 2) Дождаться результат в ивенте
import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnSuggest } from '@/fsd/data/utils/subscribeOnSuggests';
import { getDuration, markEnd, markStart } from '@/fsd/shared/utils/timeMarkHelper';
import { OrderDoneShelf2BoxRequest } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useSuggests } from '@/store/modules/suggests';
import { AxiosError } from 'axios';

export interface Shelf2BoxOptions {
  onValidateError?: (e: string) => void;
  beforeRequest?: () => void;
  afterRequest?: () => void;
  measureRequest?: (duration: number) => void;
  onRequestError?: (e: AxiosError, retry: () => Promise<any>) => void | Promise<boolean> | boolean;
  beforeEventWaiting?: () => void;
  afterEventWaiting?: () => void;
  measureEvent?: (duration: number) => void;
  onEventWaitingError?: (e: string) => void;
  closeLoader?: () => void;
}

const shelf2box = async (
  order_id: string,
  payload: OrderDoneShelf2BoxRequest,
  options: Shelf2BoxOptions = {},
): Promise<boolean> => {
  const { suggest_id } = payload;
  if (!order_id || !suggest_id) {
    options.onValidateError?.('Suggest not found');
    return false;
  }
  try {
    options.beforeRequest?.();
    markStart('shelf2box-request' + order_id);
    const response = await api.order.done.shelf2box(payload);
    markEnd('shelf2box-request' + order_id);
    if (response.data?.suggests) {
      await useOrders().updateSuggestsFromResponse(response.data.suggests);
    }
    const duration = getDuration('shelf2box-request' + order_id);
    options.measureRequest?.(duration || 0);
    options.afterRequest?.();
  } catch (err: unknown) {
    options.closeLoader?.();
    const response = (err as AxiosError).response;
    if (response?.data.details?.message === '/api/tsd/order/done/shelf2box, element: suggest_id, over: suggest') {
      useSuggests().removeSuggest(order_id, payload.suggest_id);
      return false;
    }
    return options.onRequestError?.(err as AxiosError, () => shelf2box(order_id, payload, options)) || false;
  }
  try {
    markStart('shelf2box-event' + order_id);
    options.beforeEventWaiting?.();
    await useSubscribeOnSuggest(
      order_id,
      suggest_id,
    )(s => {
      if (!s) {
        return true;
      }
      if (payload.status === 'cancel') {
        if (s.status === 'request') {
          return true;
        }
      } else {
        if (s.status === 'done') {
          return true;
        }
        if (s.status === 'failed') {
          throw 'Suggest status is Failed';
        }
      }
      return false;
    });
    markEnd('shelf2box-event' + order_id);
    const duration = getDuration('shelf2box-event' + order_id);
    options.measureEvent?.(duration || 0);
    options.afterEventWaiting?.();
  } catch (e) {
    options.onEventWaitingError?.(e as string);
    options.closeLoader?.();
    return false;
  }
  options.closeLoader?.();
  return true;
};

export default shelf2box;
