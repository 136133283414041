<template>
  <ClientOrderSuggestCardContainer :data-test="`product card ${index}`">
    <div
      class="rounded-3xl h-full w-full flex flex-col overflow-scroll"
      :class="{
        'border-2 border-red-normal': suggest?.type === 'box2shelf' && suggest?.status === 'request',
      }"
    >
      <!--Шапка-->
      <div class="flex pt-5">
        <div class="flex flex-col flex-1 items-center">
          <Body3 class="mb-2" color="text-secondary">
            {{ $gettext('Полка') }}
          </Body3>
          <Title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">
            {{ shelf }}
          </Title4>
        </div>
        <OrderProductCardCount :index="index" :collected="collected.count" :suggest="suggest" :problem="problem" />
      </div>
      <!--Картинка-->
      <div class="flex-1 shrink-1" />
      <div class="relative justify-center h-48">
        <div class="w-full h-full">
          <ImageWrapper :img-src="suggest.imageSrc" :grayscale="isBox2Shelf">
            <div class="h-12 top-1/2 -translate-y-2/4 z-10 w-full absolute">
              <Badge v-if="isCompleted" class="-rotate-12" color="lime-toxic" data-test="order suggest-completed badge">
                {{ !order.isCanceled ? $gettext('Собран') : $gettext('Отсканировано') }}
              </Badge>
              <Badge
                v-if="order.isOrderPaused"
                class="-rotate-12"
                color="red-normal"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Обрабатывается оператором') }}
              </Badge>
              <Badge
                v-if="isProblem && !order.isOrderPaused"
                class="-rotate-12"
                color="red-normal"
                data-test="order product-card pause-badge"
              >
                {{ $gettext('Ждите обработки оператором') }}
              </Badge>
              <OrderProductCardBadge v-if="isBox2Shelf && suggest?.status === 'request'">
                <template #text>
                  {{
                    suggest.shelf.type === 'trash'
                      ? $gettext('Положите товар на полку Списание')
                      : $gettext('Верните этот товар обратно')
                  }}
                </template>
              </OrderProductCardBadge>
              <OrderProductCardBadge v-if="suggest.isBlocked" data-test="product block badge">
                <template #text>
                  {{ $gettext('Продукт ещё не готов') }}
                </template>
              </OrderProductCardBadge>
              <OrderProductCardBadge v-if="suggest.isCanceled" data-test="product canceled badge">
                <template #text>
                  {{ $gettext('Продукт отменён') }}
                </template>
              </OrderProductCardBadge>
            </div>
          </ImageWrapper>
        </div>
      </div>
      <div class="flex-1 shrink-1" />
      <!--Тэги-->
      <div class="flex justify-center">
        <PackageClassBadge v-if="item?.packageClass" :package_class="item.packageClass" class="mb-2" />
        <UltimaBadge v-if="item?.isUltimaParcel" class="mb-2" />
        <FragileBadge v-if="product?.fragile" class="mb-2" />
        <SpecialPackageBadge v-if="product?.special_package" class="mb-2" />
        <WeightBadge v-if="product?.isTrueWeight" :plu_code="product.plu_code" class="mb-2" />
      </div>
      <!--Название-->
      <div class="flex flex-auto justify-center items-center py-4 px-5">
        <Body2 color="text-primary" class="font-medium text-center" data-test="product title">
          {{ title }}
        </Body2>
      </div>
      <TrueMarkFlag v-if="isTrueMark" class="m-4" />
    </div>

    <template #footer>
      <LayoutFooter v-if="needCollectButton">
        <UiButton
          :is-disabled="isDisabled"
          background-color="secondary"
          data-test="collect low_weight"
          @click="collect"
        >
          <template #icon>
            <Check />
          </template>
          {{ $gettext('Собрано') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </ClientOrderSuggestCardContainer>
</template>

<script lang="ts">
import ClientOrderSuggestCardContainer from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardContainer.vue';
import OrderProductCardBadge from '@/fsd/widgets/order/RequiredCard/order-product-card-badge.vue';
import OrderProductCardCount from '@/fsd/widgets/order/RequiredCard/order-product-card-count.vue';
import TrueMarkFlag from '@/fsd/widgets/order/RequiredCard/true-mark-flag.vue';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { Problem } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useItems } from '@/store/modules/items';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import Check from '@/temp/icons/check.vue';
import Badge from '@/temp/ui/badge/badge.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Body3 from '@/ui/common/typo/body-3.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import FragileBadge from '@/ui/order/badges/fragile-badge.vue';
import PackageClassBadge from '@/ui/order/badges/package-class-badge.vue';
import SpecialPackageBadge from '@/ui/order/badges/special-package-badge.vue';
import UltimaBadge from '@/ui/order/badges/ultima-badge.vue';
import WeightBadge from '@/ui/order/badges/weight-badge.vue';
import { Collected } from '@/views/Order/types';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'ClientOrderSuggestCardClassic',
  components: {
    ClientOrderSuggestCardContainer,
    OrderProductCardBadge,
    OrderProductCardCount,
    UltimaBadge,
    SpecialPackageBadge,
    WeightBadge,
    Body3,
    Body2,
    Title4,
    FragileBadge,
    PackageClassBadge,
    Badge,
    TrueMarkFlag,
    ImageWrapper,
    LayoutFooter,
    UiButton,
    Check,
  },
  props: {
    order_id: {
      type: String,
      required: true,
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    collected: {
      type: Object as PropType<Collected>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['finish'],
  setup(props) {
    const { showLoader } = useLoader();
    const itemsStore = useItems();
    const productsStore = useProducts();
    const shelvesStore = useShelves();
    const ordersStore = useOrders();
    const userStore = useUser();

    return { showLoader, itemsStore, productsStore, shelvesStore, userStore, ordersStore };
  },
  computed: {
    order(): OrderOrder {
      return this.ordersStore.orderById(this.order_id) as OrderOrder;
    },
    expSusanin(): boolean {
      return this.userStore.experimentByName('exp_susanin');
    },
    title(): string {
      if (!this.suggest) {
        return this.product?.title || '';
      }
      if (this.suggest.vars?.mode === 'item') {
        return this.item?.title || '';
      } else {
        return this.product?.title || '';
      }
    },
    shelf(): string {
      if (!this.suggest.shelf_id) {
        return '—';
      }
      const shelf = this.shelvesStore.shelfById(this.suggest.shelf_id);
      if (shelf) {
        return shelf.title;
      }
      return '—';
    },
    isCompleted(): boolean {
      return !this.isProblem && (this.suggest.status === 'done' || this.suggest.count === this.collected.count);
    },
    isBox2Shelf(): boolean {
      return this.suggest.type === 'box2shelf';
    },
    product(): Product | undefined {
      return this.productsStore.productById(this.suggest.product_id);
    },
    item(): Item | undefined {
      return this.itemsStore.itemById(this.suggest.product_id);
    },
    isProblem(): boolean {
      return !!this.problem;
    },
    isTrueMark(): boolean {
      return this.suggest?.conditions?.need_true_mark;
    },
    isDisabled(): boolean {
      return !this.collected.count;
    },
    needCollectButton(): boolean | undefined {
      return (
        this.suggest.product &&
        this.suggest.product.isTrueWeight &&
        this.suggest.status !== 'done' &&
        this.suggest.type === 'shelf2box'
      );
    },
  },
  mounted() {
    if (!this.title && this.suggest) {
      this.suggest.loadProduct();
    }
  },
  methods: {
    finishSuggest() {
      this.$emit('finish');
    },
    async collect(): Promise<void> {
      const confirm = await this.$notification.confirmCenter({
        title: this.$gettext('Весь товар собран?'),
        text: this.$gettext('Убедитесь, что этого товара больше нет.'),
        ok: this.$gettext('Да, весь'),
        decline: this.$gettext('Отмена'),
      });
      if (!confirm) return;
      this.finishSuggest();
    },
  },
});
</script>
