import { api } from '@/fsd/data/api/api.service';
import { ModeService } from '@/services/mode.service';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

// 5 min
const CHECK_NETWORK_INTERVAL = 1000 * 60 * 5;
export const useCheckNetwork = () => {
  const sendStatus = (url: string, duration: number) => {
    const store_name = useUser().options?.constants.store.title;
    logger.error('status/internet', {
      additional: {
        url,
        duration,
        store_name,
      },
    });
  };
  const checkApi = async () => {
    try {
      const { duration } = await api.ping.api(10000);
      sendStatus('api', duration);
    } catch (e: unknown) {
      if (e instanceof Error && e.message === 'timeout of 10000ms exceeded') {
        sendStatus('api', 10000);
      }
      console.error(e);
    }
  };
  const checkEvent = async () => {
    try {
      const { duration } = await api.ping.event(10000);
      sendStatus('event', duration);
    } catch (e: unknown) {
      if (e instanceof Error && e.message === 'timeout of 10000ms exceeded') {
        sendStatus('event', 10000);
      }
      console.error(e);
    }
  };
  const checkPicker = async () => {
    try {
      if (!ModeService.isProduction()) return;
      const { duration } = await api.ping.polka(10000);
      sendStatus('static', duration);
    } catch (e: unknown) {
      if (e instanceof Error && e.message === 'timeout of 10000ms exceeded') {
        sendStatus('static', 10000);
      }
      console.error(e);
    }
  };

  const check = () => {
    checkApi();
    checkEvent();
    checkPicker();
  };

  const { isAuthenticated } = storeToRefs(useUser());

  const interval = ref();
  watch(
    isAuthenticated,
    val => {
      if (val && !interval.value) {
        interval.value = setInterval(check, CHECK_NETWORK_INTERVAL);
      } else {
        clearInterval(interval.value);
        interval.value = undefined;
      }
    },
    { immediate: true },
  );
};
