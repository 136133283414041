<template>
  <Layout class="bg-card">
    <template #header>
      <!-- Вместо слайдера добавляем одну карточку и кнопки переключения продуктов вперед - назад-->
      <ShelvesChain
        v-if="exp_untouchable"
        class="mb-1.5"
        :suggests="suggests"
        :index="activeSuggestIndex"
        @choose-suggest="chooseSuggest"
      />
    </template>
    <Swiper
      :key="suggests.length"
      :space-between="0"
      :modules="[Virtual]"
      virtual
      @swiper="setSwiper"
      @slide-change="({ activeIndex }) => chooseSuggest(activeIndex)"
    >
      <SwiperSlide
        v-for="(suggest, index) in suggests"
        :key="suggest.suggest_id"
        :virtual-index="index"
        :data-index="index"
      >
        <ClientOrderSuggestCardWrapper
          :is-visible="activeSuggestIndex === index"
          :order_id="order_id"
          :index="index"
          :suggest="suggest"
          :problem="getProblemByProduct(suggest.product_id)"
          :collected="collected[suggest.suggest_id]"
          :sherlock="sherlockStatuses[suggest.suggest_id]"
          @finish="arg => emits('finish', arg)"
          @input-sherlock-count="onSherlock"
        />
      </SwiperSlide>
    </Swiper>
    <template #footer>
      <LayoutFooter v-if="exp_susanin && suggests.length > 1" class="grid grid-cols-2 gap-x-4 items-center">
        <ProductSwipeButton
          v-if="prevSuggest"
          :suggest="prevSuggest"
          side="left"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="prev"
        />
        <Caption1 v-else class="text-center" color="day-textMinor">
          {{ $gettext('Открыт первый товар из списка') }}
        </Caption1>
        <ProductSwipeButton
          v-if="nextSuggest"
          :suggest="nextSuggest"
          side="right"
          class="px-3 py-1 bg-warmGray-75 rounded-2xl h-14"
          @click="next"
        />
        <Caption1 v-else class="text-center" color="day-textMinor">
          {{ $gettext('Открыт последний товар из списка') }}
        </Caption1>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import ClientOrderSuggestCardWrapper from '@/fsd/widgets/order/RequiredCard/ClientOrderSuggestCardWrapper.vue';
import ProductSwipeButton from '@/fsd/widgets/order/RequiredCard/product-swipe-button.vue';
import { SherlockStatus } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import { useSherlock } from '@/fsd/widgets/order/RequiredCard/sherlock/useSherlock';
import ShelvesChain from '@/fsd/widgets/order/order-with-shelves-chain/shelves-chain.vue';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { Collected } from '@/views/Order/types';
import { Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, reactive, ref, watch } from 'vue';
import { $gettext } from '../../../../temp/plugins/gettext';

const props = defineProps<{
  order_id: OrderOrder['order_id'];
  suggests: Suggest[];
  activeSuggestIndex: number;
  collected: Record<Suggest['suggest_id'], Collected>;
}>();
const emits = defineEmits(['finish', 'input-sherlock-count', 'change-active-suggest']);

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});
const swiper = ref();
const exp_untouchable = computed(() => {
  return useUser().experimentByName('exp_untouchable');
});
const exp_susanin = computed(() => {
  return useUser().experimentByName('exp_susanin');
});
const activeSuggest = computed(() => {
  return props.suggests[props.activeSuggestIndex];
});
const prevSuggest = computed(() => {
  if (props.activeSuggestIndex > 0) {
    return props.suggests[props.activeSuggestIndex - 1];
  } else {
    return undefined;
  }
});
const nextSuggest = computed(() => {
  return props.suggests[props.activeSuggestIndex + 1];
});
const sherlockStatuses = reactive<Record<Suggest['suggest_id'], SherlockStatus>>({});

const setSwiper = s => {
  swiper.value = s;
  slideTo(props.activeSuggestIndex);
};
const slideTo = (index: number) => {
  swiper.value?.slideTo(index);
};
const chooseSuggest = (activeIndex: number) => {
  emits('change-active-suggest', activeIndex);
};
const next = () => {
  chooseSuggest(props.activeSuggestIndex + 1);
};
const prev = () => {
  chooseSuggest(props.activeSuggestIndex - 1);
};
const getProblemByProduct = (product_id: Product['product_id']) => {
  if (!order.value) return undefined;
  if (order.value.target === OrderTargetEnum.canceled) return undefined;
  return order.value?.problems.find(p => p.product_id === product_id);
};
const checkSherlock = async () => {
  if (!activeSuggest.value) return;
  if (sherlockStatuses[activeSuggest.value.suggest_id] === 'skip') return;
  if (sherlockStatuses[activeSuggest.value.suggest_id] === 'need') return;
  const { checkNeedSherlock, sherlockStatus } = useSherlock(props.order_id, activeSuggest.value.suggest_id);
  sherlockStatuses[activeSuggest.value.suggest_id] = sherlockStatus.value;
  if (sherlockStatuses[activeSuggest.value.suggest_id] !== 'maybe') return;
  const needSherlock = await checkNeedSherlock();
  sherlockStatuses[activeSuggest.value.suggest_id] = needSherlock ? 'need' : 'skip';
};
const onSherlock = (count: number) => {
  sherlockStatuses[activeSuggest.value.suggest_id] = 'skip';
  emits('input-sherlock-count', count);
};

watch(
  () => props.activeSuggestIndex,
  idx => {
    slideTo(idx);
    // проверяем нужен ли шерлок когда показываем карточку
    checkSherlock();
  },
  { immediate: true },
);
</script>

<style scoped>
/* НИКОГДА И НЕ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ ТРОГАТЬ ЭТОТ КЛАСС */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slider img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
