import { api } from '@/fsd/data/api/api.service';
import ProductByBarcode from '@/models/ProductByBarcode';
import Suggest from '@/models/Suggest';
import { AudioService } from '@/services/audio.service';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export const checkBarcodeBySuggest = async (barcode: string, activeSuggest: Suggest): Promise<boolean> => {
  const productFromCache = useProducts().productByBarcode(barcode);
  if (productFromCache) {
    if (activeSuggest.product_id === productFromCache.product_id) {
      return true;
    } else {
      Notifications.error.micro($gettext('Отсканирован неверный штрихкод %{barcode}', { barcode }));
      return false;
    }
  }
  try {
    //  если ничего не нашли локально, то делаем запрос по ручке баркод
    const { data } = await api.barcode({ barcode });
    const product_ids = data.found.filter(ProductByBarcode.isProductByBarcode).map(p => p.product_id);
    if (product_ids.length === 0) {
      Notifications.error.micro($gettext('Не найден штрихкод %{barcode}', { barcode }));
      return false;
    }
    const products = await useProducts().getProductsByIds(product_ids);
    if (!products) {
      // ошибка загрузки продктов
      Notifications.error.micro($gettext('Не удалось проверить баркод. Попробуйте ещё раз'));
      return false;
    }
    const finded = products.find(p => p.product_id === activeSuggest.product_id);
    if (finded) return true;

    // получили что-то не то.
    const hasCommonParent =
      activeSuggest.product!.type_accounting === 'weight' &&
      products.some(p => p.type_accounting === 'weight' && p.parent_id === activeSuggest.product!.parent_id);
    if (hasCommonParent) {
      Notifications.error.micro($gettext('Отсканирован не тот вес'));
    } else {
      Notifications.error.micro($gettext('Отсканирован не тот товар'));
    }
    return false;
  } catch (error) {
    Notifications.error.micro($gettext('Не удалось проверить баркод. Попробуйте ещё раз'));
    console.error(error);
    AudioService.playError();
    return false;
  }
};
