<template>
  <div class="flex flex-col px-2 bg-main rounded-b-3xl">
    <body2 class="font-medium p-3" color="text-primary">{{ $gettext('Маршрут сборки') }}</body2>
    <RouteBar :active-index="index" :chips="chipsContent" @choose-chip="idx => $emit('choose-suggest', idx)" />
  </div>
</template>

<script lang="ts">
import RouteBar from '@/fsd/shared/ui/routeBar';
import { chipContent } from '@/fsd/shared/ui/routeBar/types';
import Suggest from '@/models/Suggest';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ShelvesChain',
  components: {
    RouteBar,
  },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      default: () => {
        return [] as Suggest[];
      },
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['choose-suggest'],
  computed: {
    chipsContent(): chipContent[] {
      return this.suggests?.map(suggest => ({
        text: this.getShelfTitle(suggest),
        icon: suggest.status === 'done' ? { name: 'completed' } : undefined,
      }));
    },
  },
  methods: {
    getShelfTitle(suggest): string {
      return suggest.shelf?.title || '-';
    },
  },
});
</script>
