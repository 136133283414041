import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';

interface Conditions {
  is_agree: boolean;
}

export default class SaleStowageOrder extends BaseOrder {
  public conditions: Conditions = { is_agree: true };

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
  }

  get isTrustStowage(): boolean {
    return this.conditions.is_agree;
  }
  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
