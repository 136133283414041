<template>
  <div class="h-full flex">
    <!--exp_sten_gustaf_thulin-->
    <UltimaPackage v-if="currentStep === 'UltimaPackage'" :suggests="suggests" :packing="packing" @done="nextStep" />
    <SelectBagsCountStage
      v-if="currentStep === 'SelectBagsCount'"
      :packing="packing"
      :suggests="suggests"
      :order_id="order_id"
      @done="onSelectBags"
    />
    <ConfirmAssembledProducts v-if="currentStep === 'ConfirmAssembledProducts'" :order="order" @done="nextStep" />
    <MaybeRover v-if="currentStep === 'maybe_rover'" :order_id="order_id" @done="onSetCourier" />
    <FindRover v-if="currentStep === 'FindRover'" :order="order" @done="nextStep" />
    <FinishStage v-if="currentStep === 'finishStenGustaf'" :order_id="order_id" @done="nextStep" />
    <!--Обычный сценарий-->
    <FinishOrderCardPackageInit v-if="currentStep === 'init'" @done="nextStep" />
    <SortProductCard v-if="currentStep === 'sort'" :order="order" :suggests="suggests" @ready-click="nextStep" />
    <StatusCard
      v-if="currentStep === 'finish'"
      :order_id="order_id"
      :suggests="suggests"
      :packing="packing"
      @finish="nextStep"
    />
    <!--Общие механики-->
    <RoverCard v-if="currentStep === 'rover'" :order="order" @ready-click="nextStep" />
    <FinishOrderCardQRCode v-if="currentStep === 'qrcode'" :order="order" @done="nextStep" />
  </div>
</template>

<script lang="ts" setup>
import ConfirmAssembledProducts from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/ConfirmAssembledProducts.vue';
import FindRover from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/FindRover.vue';
import FinishStage from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/FinishStage.vue';
import MaybeRover from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/MaybeRover/MaybeRover.vue';
import SelectBagsCountStage from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/SelectBags/SelectBagsCountStage.vue';
import UltimaPackage from '@/fsd/widgets/order/finish-order/exp_sten_gustaf_thulin/UltimaPackage.vue';
import FinishOrderCardPackageInit from '@/fsd/widgets/order/finish-order/finish-order-card/finish-order-card-package-init.vue';
import FinishOrderCardQRCode from '@/fsd/widgets/order/finish-order/finish-order-card/finish-order-card-qrcode.vue';
import RoverCard from '@/fsd/widgets/order/finish-order/rover-card/rover-card.vue';
import SortProductCard from '@/fsd/widgets/order/finish-order/sort-product-card/sort-product-card.vue';
import StatusCard from '@/fsd/widgets/order/finish-order/status-card/status-card.vue';
import Suggest from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getItem } from '@/utils/localStorageHelper';
import { computed, onMounted, ref } from 'vue';

enum StepEnum {
  SelectBagsCount = 'SelectBagsCount',
  UltimaPackage = 'UltimaPackage',
  ConfirmAssembledProducts = 'ConfirmAssembledProducts',
  FindRover = 'FindRover',
  init = 'init',
  sort = 'sort',
  finish = 'finish',
  finishStenGustaf = 'finishStenGustaf',
  maybe_rover = 'maybe_rover',
  rover = 'rover',
  qrcode = 'qrcode',
}

interface Props {
  order_id: OrderOrder['order_id'];
  suggests: Suggest[];
  packing: Suggest[];
}

const props = defineProps<Props>();

const emits = defineEmits(['done']);

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});
const waitingRover = computed<boolean>(() => Boolean(order.value?.attr.maybe_rover && !order.value.courier));
const steps = computed(() => {
  const steps: StepEnum[] = [];
  if (!order.value) return steps;
  const expStenGustafThulin = useUser().experimentByName(experiments.exp_sten_gustaf_thulin);
  const expCheckQr = useUser().experimentByName(experiments.exp_check_qr);

  if (expStenGustafThulin) {
    if (
      props.suggests.some(s => s.isItemSuggest && s.item?.isUltimaParcel) &&
      props.packing.some(p => p.isUltimaPackage && p.status === 'request')
    ) {
      steps.push(StepEnum.UltimaPackage);
    }
    // Показываем этап с выбором пакетов, только если есть не закрытые саджесты на упаковку либо если саджестов на
    // упаковку вообще нет. В этом случае пользователь увидит сообщение о том, что на складе не настроен сэмплинг
    if (props.packing.length === 0 || props.packing.some(p => p.status === 'request')) {
      steps.push(StepEnum.SelectBagsCount);
    }
    if (waitingRover.value) {
      steps.push(StepEnum.maybe_rover);
    }
  } else {
    steps.push(StepEnum.init);
    steps.push(StepEnum.sort);
    steps.push(StepEnum.finish);
  }

  if (expStenGustafThulin && order.value.conditions.confirm_assembled_products) {
    steps.push(StepEnum.ConfirmAssembledProducts);
  }

  if (order.value.isRover) {
    if (expStenGustafThulin) {
      steps.push(StepEnum.FindRover);
    }
    steps.push(StepEnum.rover);
  }

  if (expCheckQr) {
    steps.push(StepEnum.qrcode);
  }

  if (expStenGustafThulin) {
    steps.push(StepEnum.finishStenGustaf);
  }
  return steps;
});

const currentStep = ref<StepEnum>(steps.value[0]);
const nextStep = (step?: StepEnum) => {
  if (step) {
    currentStep.value = step;
    return;
  }
  const currentIndex = steps.value.findIndex(s => currentStep.value === s);
  if (currentIndex >= steps.value.length - 1) {
    // это был последний шаг
    emits('done');
    return;
  }
  currentStep.value = steps.value[currentIndex + 1];
};
const onSelectBags = () => {
  if (!order.value) return;
  if (order.value.conditions.confirm_assembled_products) {
    return nextStep(StepEnum.ConfirmAssembledProducts);
  }
  if (order.value.isRover) {
    return nextStep(StepEnum.FindRover);
  }
  if (waitingRover.value) {
    return nextStep(StepEnum.maybe_rover);
  }
  nextStep(StepEnum.finishStenGustaf);
};

const onSetCourier = () => {
  if (!order.value) return;
  if (order.value.isRover) {
    return nextStep(StepEnum.FindRover);
  }
  nextStep(StepEnum.finishStenGustaf);
};

onMounted(() => {
  if (!order.value) return;

  if (getItem('roverScan', order.value.order_id)) {
    const roverIndex = steps.value.findIndex(s => s === StepEnum.rover);
    if (roverIndex !== -1) {
      currentStep.value = StepEnum.rover;
    }
  }
});
</script>
